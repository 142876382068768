// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  useEffect,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import en from "dayjs/locale/en-au";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table";
import { changeTableFilters } from "@fas/cpa-state-manager/redux/actions/table";
import { getTableData, getTableFilters, getTableTotalData } from "@fas/cpa-state-manager/services/selectors/table";
import {
  bindActionCreators,
  type Dispatch,
} from "redux";
import { connect } from "react-redux";
import { getDashboardDataSaga } from "@fas/cpa-state-manager/redux/actions/dashboard";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import { presets } from "../../helpers/generators";

import type {
  Props,
  OwnProps,
  StateToProps,
  DispatchToProps,
} from "./types/Dashboard.types";
import Chart from "../../components/Chart";
import { TableComponent } from "../../components/TableComponent";
import { formatDate } from "../../components/Chart/Chart";
import type { Column } from "../../components/TableComponent/types/TableComponent.types";
import { DASHBOARD_TABLE } from "../../helpers/constants";
import type { ItemData, TotalData } from "../../services/dashboardApi";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  data: getTableData(state, DASHBOARD_TABLE),
  // $FlowFixMe conflict type with lib
  dataTotal: getTableTotalData(state, DASHBOARD_TABLE),
  filters: getTableFilters(state, DASHBOARD_TABLE),
  loading: getLoadingState(state, "getDashboardDataLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetDashboardData: getDashboardDataSaga,
  onFiltersChange: (filters: Filters) => changeTableFilters(DASHBOARD_TABLE, filters),
}, dispatch);

const columns: Array<Column<ItemData>> = [
  {
    field: "date",
    label: "Date",
    key: "date",
    render: ({ row }) => (formatDate(row.date) === "Invalid Date" ? row.date : formatDate(row.date)),
  },
  {
    field: "quantity",
    label: "Leads",
    key: "quantity",
  },
  {
    field: "cost",
    label: "Average CPL, €",
    key: "cost",
    render: ({ row }) => `€${row.cost || 0}`,
  },
  {
    field: "amount",
    label: "Total Commission, €",
    key: "amount",
    render: ({ row }) => `€${row.amount || 0}`,
  },
];
const totalColumns: Array<Column<TotalData>> = [
  {
    field: "",
    label: "",
    key: "total",
    render: () => "Total",
  },
  {
    field: "quantity",
    label: "",
    key: "quantity",
  },
  {
    field: "cost",
    label: "",
    key: "cost",
    render: (): string => "-",
  },
  {
    field: "amount",
    label: "",
    sortable: true,
    key: "amount",
    render: ({ row }) => `€${row.amount || 0}`,
  },
];

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: theme.palette.primary.main,
  },
}));

const Dashboard: StatelessFunctionalComponent<Props> = ({
  data = [],
  dataTotal,
  filters,
  onGetDashboardData,
  onFiltersChange,
  loading,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetDashboardData();
    }
  }, []);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" pb={2}>
          <Typography variant="h5">Dashboard</Typography>
          <Box display="flex">
            <DateRangePicker
              // $FlowFixMe filters type contains array but this must be string
              startDate={filters.dateFrom}
              // $FlowFixMe filters type contains array but this must be string
              endDate={filters.dateTo}
              onChangeDate={({ startDate, endDate }) => onFiltersChange({ dateFrom: startDate, dateTo: endDate })}
              onChangePreset={({ startDate, endDate }) => onFiltersChange({ dateFrom: startDate, dateTo: endDate })}
              presets={presets}
              locale={en}
              format="YYYY/MM/DD"
              mask="9999-99-99 - 9999-99-99"
            />
            <Box pl={1}>
              <Button variant="contained" color="primary" disabled={loading} onClick={onGetDashboardData}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
        <Box component={Paper}>
          <Box position="absolute" pt={1} pl={3}>
            <Typography variant="h6">Chart report</Typography>
          </Box>
          <Chart data={data} />
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Box mt={3} display="flex" justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Box>
        )}
        { isShowData && (
          <>
            <Box component={Paper} mt={3} p={3}>
              <Box pb={2}>
                <Typography variant="h6">Table report</Typography>
              </Box>
              <TableComponent
                classes={classes}
                columns={columns}
                data={data}
                totalColumns={totalColumns}
                totalData={dataTotal}
              />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Dashboard);
